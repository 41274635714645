import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3874a9fe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content" }
const _hoisted_2 = {
  key: 2,
  class: "store"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProjectIcon = _resolveComponent("ProjectIcon")!
  const _component_FirstIconView = _resolveComponent("FirstIconView")!
  const _component_RiDoubleQuotesR = _resolveComponent("RiDoubleQuotesR")!
  const _component_SkillGroupView = _resolveComponent("SkillGroupView")!
  const _component_HashTagGroupView = _resolveComponent("HashTagGroupView")!
  const _component_RiHomeSmileFill = _resolveComponent("RiHomeSmileFill")!
  const _component_RiNotionFill = _resolveComponent("RiNotionFill")!
  const _component_AppStoreButton = _resolveComponent("AppStoreButton")!
  const _component_GooglePlayButton = _resolveComponent("GooglePlayButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h3", null, [
      (_ctx.project.appIcon)
        ? (_openBlock(), _createBlock(_component_ProjectIcon, {
            key: 0,
            icon: _ctx.project.appIcon,
            appname: _ctx.project.title
          }, null, 8, ["icon", "appname"]))
        : _createCommentVNode("", true),
      (_ctx.project.icon)
        ? (_openBlock(), _createBlock(_component_FirstIconView, {
            key: 1,
            icon: _ctx.project.icon,
            class: _normalizeClass([
          'title-icon',
          { 'title-upper': _ctx.project.icon === 'RiDoubleQuotesL' },
        ])
          }, null, 8, ["icon", "class"]))
        : _createCommentVNode("", true),
      _createTextVNode(" " + _toDisplayString(_ctx.t(_ctx.project.title)) + " ", 1),
      (_ctx.project.icon === 'RiDoubleQuotesL')
        ? (_openBlock(), _createBlock(_component_RiDoubleQuotesR, {
            key: 2,
            class: "title-icon title-upper"
          }))
        : _createCommentVNode("", true)
    ]),
    (_ctx.project.skills)
      ? (_openBlock(), _createBlock(_component_SkillGroupView, {
          key: 0,
          skills: _ctx.project.skills
        }, null, 8, ["skills"]))
      : _createCommentVNode("", true),
    _createElementVNode("p", null, _toDisplayString(_ctx.t(_ctx.project.description)), 1),
    (_ctx.project.hashtags)
      ? (_openBlock(), _createBlock(_component_HashTagGroupView, {
          key: 1,
          hashtags: _ctx.project.hashtags.map((hashtag) => _ctx.t(hashtag))
        }, null, 8, ["hashtags"]))
      : _createCommentVNode("", true),
    (_ctx.project.links)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.project.links.home)
            ? (_openBlock(), _createBlock(_component_RiHomeSmileFill, {
                key: 0,
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goToExternalUrl(_ctx.project.links.home))),
                class: "store-icon"
              }))
            : _createCommentVNode("", true),
          (_ctx.project.links.notion)
            ? (_openBlock(), _createBlock(_component_RiNotionFill, {
                key: 1,
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goToExternalUrl(_ctx.project.links.notion))),
                class: "store-icon"
              }))
            : _createCommentVNode("", true),
          (_ctx.project.links.appstore)
            ? (_openBlock(), _createBlock(_component_AppStoreButton, {
                key: 2,
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.goToExternalUrl(_ctx.project.links.appstore))),
                class: "appstore"
              }))
            : _createCommentVNode("", true),
          (_ctx.project.links.googleplay)
            ? (_openBlock(), _createBlock(_component_GooglePlayButton, {
                key: 3,
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.goToExternalUrl(_ctx.project.links.googleplay))),
                class: "googleplay"
              }))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}