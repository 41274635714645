export default {
  header: {},
  projects: [
    {
      icon: 'RiDoubleQuotesL',
      title: 'title_fillit',
      skills: ['global', 'flutter', 'cloud', 'firebase', 'apple', 'android'],
      description: 'description_fillit',
    },
    {
      // icon: 'RiAlarmFill',
      title: 'title_lablabl',
      skills: ['global', 'flutter', 'apple', 'android'],
      screens: [
        'https://is1-ssl.mzstatic.com/image/thumb/PurpleSource122/v4/9e/c3/aa/9ec3aa63-2ed7-0bb0-ebef-a94a70f90d2d/29bba0b0-89ff-4e9b-b5e7-f57636fb3fa4_Apple_iPhone_11_Pro_Max_Screenshot_0.png/230x0w.webp',
        'https://is1-ssl.mzstatic.com/image/thumb/PurpleSource112/v4/ea/44/a5/ea44a596-6615-6487-6698-80dffcf41627/da596a56-d6cf-4646-ac68-213a81942f2b_Apple_iPhone_11_Pro_Max_Screenshot_1.png/230x0w.webp',
      ],
      appIcon:
        'https://is1-ssl.mzstatic.com/image/thumb/Purple221/v4/95/10/42/951042cf-97d4-3536-f34b-4f4b9e75fdb0/AppIcon-0-0-1x_U007emarketing-0-10-0-85-220.png/1024x1024bb.png',
      description: 'description_lablabl',
      links: {
        home: 'https://lablabl.monthly-james.com',
        notion:
          'https://monthly-james.notion.site/la-bla-bl-b3f7b73c31c1484fb059970bfcb64202',
        appstore: 'https://lablabl.page.link/dmCn',
        googleplay: 'https://lablabl.page.link/jofZ',
      },
      hashtags: [
        '#la.bla.bl',
        '#global',
        '#alarm',
        '#MiracleMorning',
        '#SayGoodbyeToBoringAlarms',
      ],
    },
    {
      // icon: 'RiMapPinUserFill',
      title: 'title_withmap',
      skills: [
        'global',
        'flutter',
        'cloud',
        'firebase',
        'vue',
        'html5',
        'apple',
        'android',
      ],
      screens: [
        'https://is1-ssl.mzstatic.com/image/thumb/PurpleSource116/v4/51/7b/48/517b4859-25a6-fbc4-6c58-ea8a1fe92c5a/02de3a5b-b1da-4394-84aa-c7685c3829b6_Apple_iPhone_11_Pro_Max_Screenshot_0.png/230x0w.webp',
        'https://is1-ssl.mzstatic.com/image/thumb/PurpleSource126/v4/5d/da/15/5dda15e6-3049-f437-afc0-84b67d4d8d3d/a7e5c475-c281-4dab-8168-81cdd191833a_Apple_iPhone_11_Pro_Max_Screenshot_1.png/230x0w.webp',
      ],
      appIcon:
        'https://is1-ssl.mzstatic.com/image/thumb/Purple112/v4/c4/91/52/c49152b6-fde1-73f2-5588-765c36b3e8b5/AppIcon-0-0-1x_U007emarketing-0-10-0-85-220.png/1024x1024bb.png',
      description: 'description_withmap',
      links: {
        home: 'https://www.with-map.com',
        notion: 'https://withmap.page.link/naxz',
        appstore: 'https://withmap.page.link/ZCg5',
        googleplay: 'https://withmap.page.link/85EH',
      },
      hashtags: [
        '#withmap',
        '#global',
        '#GPS',
        '#LocationSharing',
        '#FamilyLocationTracking',
      ],
    },
    {
      // icon: 'RiParkingFill',
      title: 'title_cuckoo',
      skills: [
        'global',
        'flutter',
        'cloud',
        'firebase',
        'vue',
        'html5',
        'mic',
        'messenger',
        'apple',
        'android',
      ],
      screens: [
        'https://is2-ssl.mzstatic.com/image/thumb/PurpleSource126/v4/80/5f/c5/805fc529-7fb7-a182-e010-3c74d9cd3381/4aa576f0-c16f-4730-babd-3286bee91113_Simulator_Screenshot_-_iPhone_15_Pro_Max_-_2023-11-07_at_20.35.35.png/0x0ss.png',
        'https://is2-ssl.mzstatic.com/image/thumb/PurpleSource116/v4/fb/5f/7e/fb5f7e60-93de-03c5-24a1-9831016728b1/afd21dcc-668f-4fc0-91cb-fa6d8a5575ef_Simulator_Screenshot_-_iPhone_15_Pro_Max_-_2023-11-07_at_19.34.14.png/0x0ss.png',
        'https://is2-ssl.mzstatic.com/image/thumb/PurpleSource116/v4/20/c6/ee/20c6ee79-f2f5-278b-67be-bd1b54a4ca40/253f2fdc-614b-4780-bfc0-a6544e00551c_Simulator_Screenshot_-_iPhone_15_Pro_Max_-_2023-11-07_at_19.34.18.png/0x0ss.png',
        'https://is2-ssl.mzstatic.com/image/thumb/PurpleSource126/v4/f1/3f/59/f13f5913-5a4c-ddb7-ba50-841283561db0/265338fc-efb4-4e77-9c81-5356a1b44672_Simulator_Screenshot_-_iPhone_15_Pro_Max_-_2023-11-07_at_19.34.37.png/0x0ss.png',
      ],
      appIcon:
        'https://is1-ssl.mzstatic.com/image/thumb/Purple126/v4/9c/38/3a/9c383aee-01a6-d7aa-ebab-8b75a03402c5/AppIcon-0-0-1x_U007emarketing-0-10-0-85-220.png/1024x1024bb.png',
      description: 'description_cuckoo',
      links: {
        notion: 'https://cctouch.page.link/dxv5',
        appstore: 'https://cctouch.page.link/Ak8K',
        googleplay: 'https://cctouch.page.link/uEsh',
      },
      hashtags: [
        '#CuckooParkingAlert',
        '#KoreanOnly',
        '#ParkingAlert',
        '#ParkingRevolution',
        '#SafeParking',
      ],
    },
    {
      icon: 'RiRocketFill',
      title: 'title_start',
      // skills: [
      //   'usersmile',
      //   'homesmile',
      //   'chatsmile2',
      //   'bearsmile',
      //   'ghostsmile',
      //   'starsmile',
      //   'rocket',
      // ],
      description: 'description_start',
    },
  ],
};
