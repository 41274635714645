import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_particles = _resolveComponent("vue-particles")!

  return (_openBlock(), _createBlock(_component_vue_particles, {
    id: "tsparticles",
    onParticlesLoaded: _ctx.particlesLoaded,
    options: _ctx.optionZero
  }, null, 8, ["onParticlesLoaded", "options"]))
}