import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2a9ecdd4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "application"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.skills && _ctx.skills.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.skills, (skill, index) => {
          return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.getSkillIcon(skill)), {
            key: index,
            class: "store-icon"
          }))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}