import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1fa7099a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ParticlesView = _resolveComponent("ParticlesView")!
  const _component_LocaleMenuView = _resolveComponent("LocaleMenuView")!
  const _component_VerticalTimeLineView = _resolveComponent("VerticalTimeLineView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ParticlesView),
    _createVNode(_component_LocaleMenuView),
    _createVNode(_component_VerticalTimeLineView)
  ]))
}