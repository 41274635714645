const optionZero = {
  key: 'basic',
  name: 'Basic',
  smooth: true,
  pauseOnBlur: true,
  pauseOnOutsideViewport: true,
  background: {
    color: '#1a1a29',
  },
  fullScreen: {
    zIndex: -1,
  },
  detectRetina: true,
  fpsLimit: 120,
  motion: {
    disable: true,
    reduce: {
      factor: 4,
      value: true,
    },
  },
  particles: {
    shape: {
      type: 'circle',
    },
    number: {
      value: 120,
      density: {
        enable: true,
      },
      limit: {
        enable: true,
        mode: 'delete',
        value: 0,
      },
    },
    bounce: {
      horizontal: {
        value: 1,
      },
      vertical: {
        value: 1,
      },
    },
    collisions: {
      absorb: {
        speed: 2,
      },
      bounce: {
        enable: true,
        horizontal: {
          value: 1,
        },
        vertical: {
          value: 1,
        },
        maxSpeed: 50,
        mode: 'bounce',
      },
      overlap: {
        enable: true,
        retries: 0,
      },
    },
    color: {
      value: '#fff',
      animation: {
        enable: true,
        h: {
          count: 0,
          speed: 1,
          decay: 0,
          delay: 0,
          sync: true,
          offset: 0,
        },
        s: {
          count: 0,
          speed: 1,
          decay: 0,
          delay: 0,
          sync: true,
          offset: 0,
        },
        l: {
          count: 0,
          speed: 1,
          decay: 0,
          delay: 0,
          sync: true,
          offset: 0,
        },
      },
    },
    effect: {
      close: true,
      fill: true,
    },
    move: {
      enable: true,
      angle: {
        offset: 0,
        value: 90,
      },
      attract: {
        distance: 200,
        rotate: {
          x: 3000,
          y: 3000,
        },
      },
      center: {
        x: 50,
        y: 50,
        mode: 'percent',
        radius: 0,
      },
      decay: 0,
    },
    opacity: {
      value: {
        min: 0.1,
        max: 0.5,
      },
      animation: {
        enable: true,
        count: 0,
        speed: 3,
        decay: 0,
        delay: 0,
        mode: 'auto',
        startValue: 'random',
        destroy: 'none',
      },
      reduceDuplicates: false,
    },
    size: {
      value: {
        min: 1,
        max: 5,
      },
    },
    links: {
      enable: true,
      color: '#ffffff',
      distance: 200,
      opacity: 0.3,
      width: 1,
    },
    twinkle: {
      lines: {
        enable: true,
        frequency: 0.005,
        opacity: 1,
        color: {
          value: '#ff0000',
        },
      },
      particles: {
        enable: true,
        frequency: 0.05,
        opacity: 1,
        color: {
          value: '#ffcc00',
        },
      },
    },
    // move: {
    //   enable: true,
    //   speed: 2,
    // },
  },
  interactivity: {
    detectsOn: 'window',
    events: {
      onHover: {
        enable: true,
        mode: 'bubble',
        parallax: {
          enable: true,
          force: 60,
          smooth: 10,
        },
      },
      onClick: {
        enable: true,
        mode: 'push',
      },
      resize: {
        enable: true,
        delay: 0.5,
      },
    },
    modes: {
      trail: {
        delay: 1,
        pauseOnStop: false,
        quantity: 1,
      },
      attract: {
        distance: 200,
        duration: 0.4,
        easing: 'ease-out-quad',
        factor: 1,
        maxSpeed: 50,
        speed: 1,
      },
      connect: {
        distance: 80,
        links: {
          opacity: 0.5,
        },
        radius: 60,
      },
      bounce: {
        distance: 200,
      },
      grab: {
        distance: 400,
        links: {
          blink: true,
          consent: true,
          opacity: 0.1,
        },
      },
      bubble: {
        distance: 400,
        duration: 2,
        color: {
          value: ['#ffcc00'],
          animation: {
            enable: true,
            speed: 400,
            sync: true,
          },
        },
        opacity: 0.8,
        mix: false,
        size: 20,
        speed: 3,
        divs: {
          distance: 200,
          duration: 0.4,
          mix: false,
        },
      },
      repulse: {
        distance: 200,
        duration: 0.4,
        factor: 100,
        speed: 1,
        maxSpeed: 100,
        easing: 'ease-out-quad',
        divs: {
          distance: 200,
          duration: 0.4,
          factor: 100,
          speed: 1,
          maxSpeed: 50,
          easing: 'ease-out-quad',
        },
      },
      push: {
        default: true,
        easing: 'ease-out-quad',
        quantity: {
          min: 1,
          max: 10,
        },
      },
      remove: {
        particles_nb: 2,
      },
      slow: {
        factor: 3,
        radius: 200,
      },
      light: {
        area: {
          gradient: {
            start: {
              value: '#ffffff',
            },
            stop: {
              value: '#000000',
            },
            radius: 1000,
          },
        },
      },
    },
  },
};
const optionZeroOne = {
  key: 'basic',
  name: 'Basic',
  particles: {
    number: {
      value: 100,
      density: {
        enable: true,
      },
    },
    color: {
      value: '#ff0000',
      animation: {
        enable: true,
        speed: 20,
        sync: true,
      },
    },
    shape: {
      type: 'circle',
    },
    opacity: {
      value: 0.5,
    },
    size: {
      value: {
        min: 1,
        max: 4,
      },
    },
    links: {
      enable: true,
      distance: 200,
      color: '#ffffff',
      opacity: 0.3,
      width: 1,
    },
    move: {
      enable: true,
      speed: 6,
    },
  },
  interactivity: {
    events: {
      onHover: {
        enable: true,
        mode: 'repulse',
      },
      onClick: {
        enable: true,
        mode: 'push',
      },
    },
    modes: {
      repulse: {
        distance: 200,
      },
      push: {
        quantity: 4,
      },
    },
  },
  background: {
    color: '#1d1d35',
  },
};
const optionOne = {
  background: {
    color: '#366d9a',
  },
  fullScreen: {
    zIndex: -1,
  },
  interactivity: {
    events: {
      onHover: {
        enable: true,
        mode: 'trail',
      },
    },
    modes: {
      trail: {
        delay: 0.005,
        quantity: 5,
        pauseOnStop: true,
        particles: {
          color: {
            value: '#ff0000',
            animation: {
              enable: true,
              speed: 400,
              sync: true,
            },
          },
          collisions: {
            enable: false,
          },
          links: {
            enable: false,
          },
          move: {
            outModes: {
              default: 'destroy',
            },
            speed: 2,
          },
          size: {
            value: {
              min: 1,
              max: 5,
            },
            animation: {
              enable: true,
              speed: 5,
              sync: true,
              startValue: 'min',
              destroy: 'max',
            },
          },
        },
      },
    },
  },
  particles: {
    color: {
      animation: {
        enable: true,
        sync: false,
        speed: 50,
      },
      value: '#ff0000',
    },
    links: {
      color: 'random',
      enable: true,
    },
    move: {
      enable: true,
    },
    number: {
      value: 100,
      density: {
        enable: true,
      },
    },
    opacity: {
      animation: {
        enable: true,
        speed: 0.5,
      },
      value: {
        min: 0.3,
        max: 0.8,
      },
    },
    size: {
      animation: {
        enable: true,
        speed: 3,
      },
      value: {
        min: 1,
        max: 3,
      },
    },
  },
  emitters: [],
};
const optionTwo = {
  particles: {
    number: {
      value: 400,
      density: {
        enable: true,
        value_area: 100,
      },
    },
    color: {
      value: '#000000',
    },
    shape: {
      type: 'circle',
      stroke: {
        width: 2,
        color: '#D4F6F0',
      },
      polygon: {
        nb_sides: 400,
      },
    },
    opacity: {
      value: 1,
      random: true,
      anim: {
        enable: true,
        speed: 10,
        opacity_min: 0,
        sync: false,
      },
    },
    size: {
      value: 2,
      random: true,
      anim: {
        enable: false,
        speed: 10,
        size_min: 1,
        sync: false,
      },
    },
    line_linked: {
      enable: false,
      distance: 150,
      color: '#D4F6F0',
      opacity: 0.4,
      width: 3,
    },
    move: {
      enable: true,
      speed: 6,
      direction: 'none',
      random: true,
      straight: false,
      out_mode: 'out',
      bounce: false,
      attract: {
        enable: false,
        rotateX: 600,
        rotateY: 600,
      },
    },
  },
  interactivity: {
    detect_on: 'canvas',
    events: {
      onhover: {
        enable: true,
        mode: 'bubble',
      },
      onclick: {
        enable: false,
        mode: 'repulse',
      },
      resize: true,
    },
    modes: {
      grab: {
        distance: 400,
        line_linked: {
          opacity: 1,
        },
      },
      bubble: {
        distance: 200,
        size: 5,
        duration: 10,
        opacity: 0,
        speed: 3,
      },
      repulse: {
        distance: 400,
        duration: 0.4,
      },
      push: {
        particles_nb: 4,
      },
      remove: {
        particles_nb: 2,
      },
    },
  },
  retinaDetect: true,
};
const optionMask = {
  background: {
    color: '#000',
  },
  detectRetina: false,
  fpsLimit: 60,
  interactivity: {
    detectsOn: 'canvas',
    events: {
      onHover: {
        enable: true,
        mode: 'bubble',
      },
      resize: true,
    },
    modes: {
      bubble: {
        color: '#ffff00',
        distance: 100,
        duration: 2,
        opacity: 1,
        size: 10,
        speed: 3,
      },
    },
  },
  particles: {
    color: {
      value: '#fff',
    },
    links: {
      blink: false,
      color: '#fff',
      consent: false,
      distance: 20,
      enable: true,
      opacity: 0.8,
      width: 1,
    },
    move: {
      attract: {
        enable: false,
        rotate: {
          x: 600,
          y: 1200,
        },
      },
      bounce: false,
      direction: 'none',
      enable: true,
      outMode: 'bounce',
      random: false,
      speed: 1,
      straight: false,
    },
    number: {
      density: {
        enable: false,
        area: 2000,
      },
      limit: 0,
      value: 400,
    },
    opacity: {
      animation: {
        enable: true,
        minimumValue: 0.3,
        speed: 0.5,
        sync: false,
      },
      random: { enable: true, minimumValue: 0.3 },
      value: 0.8,
    },
    shape: {
      type: 'circle',
    },
    size: {
      value: 1,
    },
  },
  polygon: {
    move: {
      radius: 10,
    },
    inlineArrangement: 'equidistant',
    scale: 1,
    type: 'inline',
    position: {
      x: 20,
      y: 40,
    },
    data: {
      path: 'M129.6,200.8c0,62.6-26.5,78.4-80.2,105c6.9,4.6,16.2,10.1,32,17.6c41.4-19.6,81.4-45.3,81.4-113.8V65.1h-33.2V200.8z M98.1,65.1H0v28.7h65v91.9c0,42-2.5,45.9-57.1,70.5c3.8,9.6,9.3,19,16.5,27.5c61.7-27.5,73.7-41.7,73.7-89.3V65.1z',
      size: {
        width: 162.7,
        height: 323.3,
      },
    },
  },
};
export { optionZero, optionOne, optionTwo, optionMask, optionZeroOne };
