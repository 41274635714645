<template>
  <img :src="icon" :alt="appname" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ProjectIcon',
  props: {
    icon: {
      type: String,
      required: true,
    },
    appname: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {};
  },
});
</script>

<style scoped>
img {
  width: 1.2em;
  border-radius: 0.2em; /* 원하는 라운드 크기로 변경 가능 */
  vertical-align: text-bottom;
  border: 2px solid #383859;
}
</style>
