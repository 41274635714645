import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7e1a93ec"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "language-switcher" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("ul", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableLocales, (lang) => {
        return (_openBlock(), _createElementBlock("li", {
          key: lang,
          class: _normalizeClass({ active: _ctx.locale === lang })
        }, [
          _createElementVNode("button", {
            onClick: ($event: any) => (_ctx.switchLocale(lang))
          }, _toDisplayString(lang == 'en' ? 'English' : '한국어'), 9, _hoisted_2)
        ], 2))
      }), 128))
    ])
  ]))
}