<template>
  <div v-if="hashtags && hashtags.length > 0" class="hashtag">
    {{ hashtags.join(', ') }}
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'HashTagGroupView',
  props: {
    hashtags: {
      type: Array as PropType<Array<string>>,
      required: true,
    },
  },
  setup() {
    return {};
  },
});
</script>

<style scoped>
.hashtag {
  color: #676767;
  font-weight: 600;
}
</style>
