<template>
  <component
    v-if="icon"
    :is="iconComponent"
    :class="['title-icon', { 'title-upper': icon === 'RiDoubleQuotesL' }]"
  />
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import {
  RiDoubleQuotesL,
  RiMapPinUserFill,
  RiParkingFill,
  RiAlarmFill,
  RiGlobalFill,
  RiRocketFill,
} from 'vue-remix-icons';

export default defineComponent({
  name: 'FirstIconView',
  props: {
    icon: {
      type: String,
      required: true,
    },
  },
  components: {
    RiDoubleQuotesL,
    RiMapPinUserFill,
    RiParkingFill,
    RiAlarmFill,
    RiGlobalFill,
    RiRocketFill,
  },
  setup(props) {
    const iconComponent = computed(() => {
      switch (props.icon) {
        case 'RiMapPinUserFill':
          return RiMapPinUserFill;
        case 'RiGlobalFill':
          return RiGlobalFill;
        case 'RiParkingFill':
          return RiParkingFill;
        case 'RiAlarmFill':
          return RiAlarmFill;
        case 'RiDoubleQuotesL':
          return RiDoubleQuotesL;
        case 'RiRocketFill':
          return RiRocketFill;
        default:
          return RiDoubleQuotesL; // Default icon
      }
    });
    return { iconComponent };
  },
});
</script>

<style scoped></style>
