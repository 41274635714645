<template>
  <div class="container">
    <ParticlesView />
    <LocaleMenuView />
    <VerticalTimeLineView />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import VerticalTimeLineView from '@/views/timeline/VerticalTimeLineView.vue';
import ParticlesView from '@/views/ParticlesView.vue';
import LocaleMenuView from '@/views/timeline/LocaleMenuView.vue';

export default defineComponent({
  name: 'TimeLineView',
  components: {
    ParticlesView,
    LocaleMenuView,
    VerticalTimeLineView,
  },
  setup() {
    return {};
  },
});
</script>

<style scoped>
.container {
  width: 100vw;
  height: auto;
}
</style>
