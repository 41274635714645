import { openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-224a7b5e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  src: "https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg",
  alt: "AppStore"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("img", _hoisted_1))
}