<template>
  <img
    src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Google_Play_Store_badge_EN.svg/2560px-Google_Play_Store_badge_EN.svg.png"
    alt="Google play"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'GooglePlayButton',
  setup() {
    return {};
  },
});
</script>

<style scoped>
img {
  vertical-align: text-bottom;
}
</style>
