<template>
  <div class="content">
    <h3>
      <ProjectIcon
        v-if="project.appIcon"
        :icon="project.appIcon"
        :appname="project.title"
      />
      <FirstIconView
        v-if="project.icon"
        :icon="project.icon"
        :class="[
          'title-icon',
          { 'title-upper': project.icon === 'RiDoubleQuotesL' },
        ]"
      />
      {{ t(project.title) }}
      <RiDoubleQuotesR
        v-if="project.icon === 'RiDoubleQuotesL'"
        class="title-icon title-upper"
      />
    </h3>
    <SkillGroupView v-if="project.skills" :skills="project.skills" />
    <p>{{ t(project.description) }}</p>
    <HashTagGroupView
      v-if="project.hashtags"
      :hashtags="project.hashtags.map((hashtag) => t(hashtag))"
    />
    <div v-if="project.links" class="store">
      <RiHomeSmileFill
        v-if="project.links.home"
        @click="goToExternalUrl(project.links.home)"
        class="store-icon"
      />
      <RiNotionFill
        v-if="project.links.notion"
        @click="goToExternalUrl(project.links.notion)"
        class="store-icon"
      />
      <AppStoreButton
        v-if="project.links.appstore"
        @click="goToExternalUrl(project.links.appstore)"
        class="appstore"
      />
      <GooglePlayButton
        v-if="project.links.googleplay"
        @click="goToExternalUrl(project.links.googleplay)"
        class="googleplay"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import {
  RiDoubleQuotesR,
  RiNotionFill,
  RiHomeSmileFill,
} from 'vue-remix-icons';
import { useI18n } from 'vue-i18n';
import ProjectIcon from '@/components/ProjectIcon.vue';
import FirstIconView from '@/views/timeline/FirstIconView.vue';
import SkillGroupView from '@/views/timeline/SkillGroupView.vue';
import HashTagGroupView from '@/views/timeline/HashTagGroupView.vue';
import AppStoreButton from '@/components/button/AppStoreButton.vue';
import GooglePlayButton from '@/components/button/GooglePlayButton.vue';

export default defineComponent({
  name: 'VerticalTimeLineItemView',
  props: {
    project: {
      type: Object as PropType<IProject>,
      required: true,
    },
  },
  methods: {
    goToExternalUrl(url: string) {
      window.location.href = url;
    },
  },
  setup() {
    const { t } = useI18n();
    return {
      t,
    };
  },
  components: {
    RiDoubleQuotesR,
    RiHomeSmileFill,
    RiNotionFill,
    ProjectIcon,
    AppStoreButton,
    GooglePlayButton,
    SkillGroupView,
    HashTagGroupView,
    FirstIconView,
  },
});
</script>

<style scoped lang="scss">
.content {
  animation: revealFromLeft 0.5s 0s ease both 1;
  h3 {
    font-size: 1.8em;
    font-weight: 500;
    line-height: 1.2;
    // letter-spacing: -0.003em;

    @media all and (min-width: 40em) {
      font-size: 2em;
    }
  }
}
.title-icon {
  width: 1.2em;
  vertical-align: bottom;
  color: #efefef;
}
.title-upper {
  vertical-align: super !important;
  width: 0.7em !important;
}
h1,
h2,
h3 {
  font-weight: 900;
  color: #c7c7c7;
}

p {
  line-height: 1.5;
  color: #9f9f9f;
  position: relative;
}
.store {
  // padding: 0px 20px 0px 0px !important;
  // width: 200px;
  // height: $store-height;
  margin-top: 1em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .store-icon {
    color: #efefef;
    width: 2em;
    margin-right: 0.5em;
    cursor: pointer;
  }
  .appstore {
    height: 2em !important;
    margin-right: 0.5em;
    cursor: pointer;
  }
  .googleplay {
    height: 2em !important;
    margin-right: 0.5em;
    cursor: pointer;
  }
}
</style>
