<template>
  <div v-if="skills && skills.length > 0" class="application">
    <component
      v-for="(skill, index) in skills"
      :key="index"
      :is="getSkillIcon(skill)"
      class="store-icon"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import {
  RiUserSmileFill,
  RiDoubleQuotesL,
  RiDoubleQuotesR,
  RiFirebaseFill,
  RiMapPinUserFill,
  RiParkingFill,
  RiAppleFill,
  RiAlarmFill,
  RiAndroidFill,
  RiGlobalFill,
  RiMessengerFill,
  RiCloudFill,
  RiVuejsFill,
  RiAppStoreFill,
  RiGooglePlayFill,
  RiHtml5Fill,
  RiFlutterFill,
  RiMicFill,
  RiHomeSmileFill,
  RiChatSmile2Fill,
  RiBearSmileFill,
  RiGhostSmileFill,
  RiStarSmileFill,
  RiNotionFill,
  RiHeart2Fill,
  RiRocketFill,
} from 'vue-remix-icons';

export default defineComponent({
  name: 'SkillGroupView',
  props: {
    skills: {
      type: Array as PropType<Array<string>>,
      required: true,
    },
  },
  components: {
    RiUserSmileFill,
    RiDoubleQuotesL,
    RiDoubleQuotesR,
    RiCloudFill,
    RiFirebaseFill,
    RiMapPinUserFill,
    RiGlobalFill,
    RiParkingFill,
    RiAlarmFill,
    RiAppStoreFill,
    RiGooglePlayFill,
    RiMessengerFill,
    RiVuejsFill,
    RiMicFill,
    RiAppleFill,
    RiAndroidFill,
    RiHtml5Fill,
    RiFlutterFill,
    RiHomeSmileFill,
    RiChatSmile2Fill,
    RiBearSmileFill,
    RiGhostSmileFill,
    RiStarSmileFill,
    RiNotionFill,
    RiHeart2Fill,
  },
  setup() {
    const getSkillIcon = (skill: string) => {
      switch (skill.toLowerCase()) {
        case 'global':
          return RiGlobalFill;
        case 'flutter':
          return RiFlutterFill;
        case 'cloud':
          return RiCloudFill;
        case 'firebase':
          return RiFirebaseFill;
        case 'html5':
          return RiHtml5Fill;
        case 'vue':
          return RiVuejsFill;
        case 'mic':
          return RiMicFill;
        case 'messenger':
          return RiMessengerFill;
        case 'apple':
          return RiAppleFill;
        case 'android':
          return RiAndroidFill;
        case 'app-store':
          return RiAppStoreFill;
        case 'google-play':
          return RiGooglePlayFill;
        case 'usersmile':
          return RiUserSmileFill;
        case 'homesmile':
          return RiHomeSmileFill;
        case 'chatsmile2':
          return RiChatSmile2Fill;
        case 'bearsmile':
          return RiBearSmileFill;
        case 'ghostsmile':
          return RiGhostSmileFill;
        case 'starsmile':
          return RiStarSmileFill;
        case 'rocket':
          return RiRocketFill;
        default:
          return RiHeart2Fill; // 기본적으로는 아이콘을 렌더링하지 않음
      }
    };
    return {
      getSkillIcon,
    };
  },
});
</script>

<style scoped lang="scss">
.application {
  .store-icon {
    color: #efefef;
    width: 1em;
    margin-right: 0.5em;
  }
}
</style>
