<template>
  <vue-particles
    id="tsparticles"
    @particles-loaded="particlesLoaded"
    :options="optionZero"
  />
</template>

<script lang="ts">
// import type { Container } from '@tsparticles/engine';
import configs from '@tsparticles/configs';
import { defineComponent } from 'vue';
import { optionZero } from '@/libs/data/particleData';

export default defineComponent({
  name: 'ParticlesView',
  props: {
    time: String,
    meridiem: String,
    mission: String,
    icon: String,
  },
  method: {},
  mounted() {
    //
    // console.log(JSON.stringify(configs.basic));
  },
  setup() {
    return {
      options: {
        ...configs.basic,
        fullScreen: {
          zIndex: -2,
        },
      },
      async particlesLoaded() {
        // console.log(container);
      },
      optionZero: optionZero,
    };
  },
});
</script>

<style>
header {
  line-height: 1.5;
}

.logo {
  display: block;
  margin: 0 auto 2rem;
}

a,
.green {
  text-decoration: none;
  color: hsla(160, 100%, 37%, 1);
  transition: 0.4s;
}
</style>
