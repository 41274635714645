import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import Particles from '@tsparticles/vue3';
import { loadSlim } from '@tsparticles/slim';
import { createI18n, type I18nOptions } from 'vue-i18n';
import messages from '@/libs/data/locale';
// 브라우저 언어 감지
const browerNavigator: { language?: string; userLanguage?: string } = navigator;
const userLang = browerNavigator.language || browerNavigator.userLanguage;
const locale = userLang?.startsWith('ko') ? 'ko' : 'en';
const options: I18nOptions = {
  legacy: false,
  locale,
  fallbackLocale: 'en',
  messages,
};
const i18n = createI18n<false, typeof options>(options);

createApp(App)
  .use(Particles, {
    init: async (engine) => {
      await loadSlim(engine); // or you can load the slim version from "@tsparticles/slim" if don't need Shapes or Animations
    },
  })
  .use(i18n)
  .use(router)
  .mount('#app');
