<template>
  <img
    src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
    alt="AppStore"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AppStoreButton',
  setup() {
    return {};
  },
});
</script>

<style scoped>
img {
  vertical-align: text-bottom;
}
</style>
