import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import TimeLineView from '@/views/TimeLineView.vue';
import ParticlesView from '@/views/ParticlesView.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'TimeLines',
    component: TimeLineView,
  },
  {
    path: '/v1',
    name: 'v1',
    component: TimeLineView,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () =>
    //   import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  },
  {
    path: '/particles',
    name: 'ParticlesView',
    component: ParticlesView,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () =>
    //   import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
