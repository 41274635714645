<template>
  <section class="block-content t-block-teal l-block-spacing">
    <div class="l-contained">
      <header class="heading-group">
        <h2>
          <div v-if="currentLocale == 'ko'">
            <span class="w100">월</span><span class="w200">간</span
            ><span class="w300">제</span><span class="w400">임</span>
            <span class="w500">쓰</span>
          </div>
          <div v-else>
            <span class="w100">M</span><span class="w200">o</span
            ><span class="w300">n</span><span class="w400">t</span
            ><span class="w500">h</span><span class="w600">l</span
            ><span class="w700">y</span> <span class="w800">James</span>
          </div>
        </h2>
        <p class="subtitle">indie hacking</p>
      </header>
      <ul class="timeline-list">
        <li v-for="(project, index) in projects" :key="index">
          <VerticalTimeLineItemView :project="(project as IProject)" />
        </li>
      </ul>
      <div class="copyright">
        <p class="w100">Monthly James Co., Ltd</p>
        <p class="w600">
          Copyright <RiCopyrightFill class="icon-copy" /> Monthly James Co., Ltd
          All Rights Reserved
        </p>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n';
import datas from '@/libs/data/datas';
import { computed, defineComponent } from 'vue';
import VerticalTimeLineItemView from '@/views/timeline/VerticalTimeLineItemView.vue';
import { RiCopyrightFill } from 'vue-remix-icons';
export default defineComponent({
  name: 'VerticalTimeLineView',
  components: {
    VerticalTimeLineItemView,
    RiCopyrightFill,
  },
  data() {
    return {
      projects: datas.projects,
    };
  },
  setup() {
    const { locale } = useI18n();
    const currentLocale = computed(() => locale.value);
    return {
      currentLocale,
    };
  },
});
</script>

<style scoped lang="scss">
$element-count: 5;
$base-delay: 0.85s;
$increment: 0.35s;

@keyframes revealFromLeft {
  0% {
    transform: translateX(-1em);
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes reveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes scaleVertical {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}

@keyframes revealScaleUp {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  50% {
    opacity: 1;
    transform: scale(1.3);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.block-content {
  min-height: 100vh;
  z-index: 100;
  p {
    font-size: 1.1em;
    font-weight: 500;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    // margin: 0 auto 1em auto;
  }

  h2 {
    text-align: center;
    margin: 1em 0 0 0;
    font-size: 3.5em;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 0.02em;

    @media all and (min-width: 40em) {
      font-size: 4.75em;
    }
  }
}

.subtitle {
  font-size: 1.5em !important;
  font-family: 'Kaushan Script', cursive;
  vertical-align: super;
  text-align: center;
  margin: 0.5em 0;
  color: #ee5f56 !important;
  z-index: 100;
}

.heading-group {
  margin: 0 0 3em 0;
}

.title-icon {
  width: 1.2em;
  vertical-align: bottom;
  color: #efefef;
}

.title-upper {
  vertical-align: super !important;
  width: 0.7em !important;
}

.application {
  .store-icon {
    color: #efefef;
    width: 1em;
    margin-right: 0.5em;
  }
}

.content {
  h3 {
    font-size: 1.8em;
    font-weight: 500;
    line-height: 1.2;
    // letter-spacing: -0.003em;

    @media all and (min-width: 40em) {
      font-size: 2em;
    }
  }

  .hashtag {
    color: #676767;
    font-weight: 600;
  }
}

h1,
h2,
h3 {
  font-weight: 900;
}

p {
  line-height: 1.5;
}

.l-block-spacing {
  min-height: 80vh;
  padding: 2em 0 4em 0;
}

.l-contained {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 1em;

  @media all and (min-width: 40em) {
    padding: 0 2em;
  }
}

.timeline-list {
  padding-bottom: 1em;
  position: relative;
  max-width: 700px;
  margin: 0 auto;
  animation: reveal 2s 0 ease both 1;
  list-style: none;

  &:before {
    content: '';
    border-left: 0.3em solid rgba(255, 255, 255, 0.7);
    left: 0.75em;
    position: absolute;
    bottom: 0;
    top: 0;
    transform-origin: 0 0;
    animation: scaleVertical 3s 1s ease both 1;
  }

  &:after {
    content: '';
    clear: both;
    position: absolute;
    bottom: 0;
    left: 0.25em;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #ddd;
    border: 5px solid #ddd;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    animation: revealScaleUp 0.75s 2.82s ease both 1;
  }

  li {
    margin: 0 0 5em 0;
    padding: 0 1.5em 0 1em;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: -2.55em;
      width: 30px;
      height: 30px;
      transform: rotation(-50%);
      border-radius: 50%;
      background: #05aa9d;
      // background: #111120;
      border: 5px solid #fff;
      box-sizing: border-box;
      animation: revealScaleUp 0.75s 0s ease both 1;
    }

    .content {
      animation: revealFromLeft 0.5s 0s ease both 1;
    }

    @for $i from 1 through $element-count {
      &:nth-child(#{$i}):before {
        animation-delay: $base-delay + ($increment * ($i - 1));
      }

      &:nth-child(#{$i}) .content {
        animation-delay: $base-delay + ($increment * ($i - 1)) + 0.2s;
      }
    }
  }
}

.t-block-teal {
  // background: #02aa9d;

  h2 {
    position: relative;
    color: #fff;
  }

  h3 {
    color: #c7c7c7;
  }

  p {
    color: #9f9f9f;
    position: relative;
  }
}
@for $i from 1 through 9 {
  .w#{$i}00 {
    letter-spacing: -0.02em;
    font-weight: $i * 100;
  }
}
.copyright {
  margin-top: 5em;
  p {
    margin: 0;
    font-size: 0.8em;
    position: relative;
    text-align: center;
    z-index: 100;
    color: #9f9f9f !important;
  }
  .icon-copy {
    width: 1em;
    color: white;
    vertical-align: text-top;
  }
}
</style>
