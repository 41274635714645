<template>
  <section class="language-switcher">
    <ul>
      <li
        v-for="lang in availableLocales"
        :key="lang"
        :class="{ active: locale === lang }"
      >
        <button @click="switchLocale(lang)">
          {{ lang == 'en' ? 'English' : '한국어' }}
        </button>
      </li>
    </ul>
  </section>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'LocaleMenuView',
  setup() {
    const { availableLocales, locale } = useI18n();
    const currentLocale = computed(() => locale.value);

    const switchLocale = (lang: string) => {
      locale.value = lang;
    };

    return {
      availableLocales,
      currentLocale,
      switchLocale,
      locale,
    };
  },
});
</script>

<style scoped lang="scss">
.language-switcher {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
  ul {
    display: flex;
    gap: 0.5em;
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      button {
        background: none;
        border: none;
        padding: 0.5em;
        cursor: pointer;
        font-size: 1em;
        color: #888;

        &.active {
          font-weight: bold;
          color: #ffcc00;
        }

        &:hover {
          color: #ffcc00;
        }
      }

      &.active button {
        font-weight: bold;
        color: #ffcc00;
      }
    }
  }
}
</style>
